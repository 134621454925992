<template>
    <div class="d-flex flex-wrap">
        <div class="courseview">
            <i class="icon icon-chevron-left pt-3" id="leftArrow"></i>
            <div id="viewContainer">
                <div id="courseshow">
                    <div
                        v-for="(i, index) in courseList"
                        :key="index"
                        class="courses"
                        @click="courseSelected = i"
                        :class="{ backgroundColor: courseSelected === i }"
                    >
                        <span @click="selectCourseforProduct(index)">
                            {{ i.name }}
                        </span>
                    </div>
                </div>
            </div>
            <i class="icon icon-chevron-right pt-3" id="rightArrow"></i>
        </div>
    </div>
</template>
<script>
import { EventBus } from './eventBus/nav-bar-event'
export default {
    data() {
        return {
            courseSelected: 1,
            numberOfCourses: '',
            courseList: [],
            course: ''
        }
    },
    props: ['NoCourses'],
    methods: {
        CoursesCreation() {
            for (var i = 1; i <= this.NoCourses; i++) {
                this.courseList.push({
                    name: i,
                    areItemsAdded: false
                })
            }
            let temp_index = this.courseList[0].name
            EventBus.$emit('callProducts', temp_index)
        },
        selectCourseforProduct(temp_index) {
            // this.courseList[index]['areItemsAdded'] = true
            // let selected_course = this.courseList[index]['name']
            EventBus.$emit('callProducts', temp_index)
        }
    },
    watch: {
        courseSelected(newValue, oldValue) {
            if (newValue) {
                let temp_index = newValue.name
                this.selectCourseforProduct(temp_index)
            }
        },
        NoCourses(newValue, oldValue) {
            if (newValue) {
                this.CoursesCreation()
            }
        }
    },
    created() {},
    mounted() {
        var view = $('#courseshow')
        var move = 100
        var sliderLimit = -400
        $('#rightArrow').click(function() {
            var currentPosition = parseInt(view.css('left'))
            if (currentPosition >= sliderLimit)
                view.stop(false, true).animate(
                    { left: '-=' + move },
                    { duration: 400 }
                )
        })
        $('#leftArrow').click(function() {
            var currentPosition = parseInt(view.css('left'))
            if (currentPosition < 0)
                view.stop(false, true).animate(
                    { left: '+=' + move },
                    { duration: 400 }
                )
        })
    }
}
</script>
<style scoped>
.backgroundColor {
    background: #16bc !important;
    color: #ffff !important;
}
.courseview {
    width: 760px !important;
    height: 44px;
    position: relative;
}

.courses {
    float: left;
    color: #00448b;
    border: 1px solid #00448b;
    border-radius: 50% !important;
    margin-left: 18px;
    font-weight: bold;
    font-size: 15px;
    margin-top: 4px;
    cursor: pointer;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 6px;
    text-align: center;
}

#courseshow {
    position: absolute;
    left: 0;
    width: 1200px;
}

#leftArrow {
    width: 10px;
    height: 44px;
    position: absolute;
    left: -2px;
}

#rightArrow {
    width: 10px;
    height: 44px;
    position: absolute;
    right: 18px;
}

#viewContainer {
    width: 716px !important;
    height: 100%;
    position: absolute;
    /* left:50%; */
    margin-left: 15px;
    overflow: hidden;
}
@media only screen and(max-width: 1288px) {
    .courseview {
        width: 760px !important;
    }
    #viewContainer {
        width: 716px !important;
    }
}
@media only screen and(max-width: 1275px) {
    .courseview {
        width: 650px !important;
    }
    #viewContainer {
        width: 600px !important;
    }
}
@media only screen and(max-width: 1140px) {
    .courseview {
        width: 600px !important;
    }
    #viewContainer {
        width: 550px !important;
    }
}
@media only screen and(max-width: 560px) {
    .courseview {
        width: 400px !important;
    }
    #viewContainer {
        width: 350px !important;
    }
}
</style>
